import styles from './Goods.module.scss';
import { images } from './images';

export const Goods = () => {

  return (
    <div id='goods' className={styles.advantages}>
      <h2 className={styles.title}>Товары</h2>

      <div className={styles.list}>
        {images.map((item, index) => {
          return (
            <a href={`${item.link}`} className={styles.list__item} key={index}>
              <div
                style={{
                  backgroundImage: `url(${item.image})`
                }}
                className={styles.list__image}
              ></div>
              <p className={styles.list__subtitle}>
                {item.title}
              </p>
            </a>
          )
        })}
      </div>

    </div>
  );
};
