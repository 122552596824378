import one from '../../assets/images/categories/1.jpg'
import two from '../../assets/images/categories/2.jpg'
import three from '../../assets/images/categories/3.jpg'
import four from '../../assets/images/categories/4.jpg'
import five from '../../assets/images/categories/5.jpg'
import six from '../../assets/images/categories/6.jpg'
import seven from '../../assets/images/categories/7.jpg'
import eight from '../../assets/images/categories/8.jpg'
import nine from '../../assets/images/categories/9.jpg'
import ten from '../../assets/images/categories/10.jpg'
import eleven from '../../assets/images/categories/11.jpg'
import twelve from '../../assets/images/categories/12.jpg'
import thirteen from '../../assets/images/categories/13.jpg'


export const images = [
  { title: 'Бытовые газовые баллоны', image: one, link: 'http://magazin.teplo-tula.ru/bytovye-gazovye-ballony/'},
  { title: 'Бытовые счетчики газа', image: two, link: 'http://magazin.teplo-tula.ru/schetchiki-gaza-bytovye/'},
  { title: 'Встраиваемая техника ', image: three, link: 'http://magazin.teplo-tula.ru/vstraivaemaya-tehnika/'},
  { title: 'Вытяжки для кухни', image: four, link: 'http://magazin.teplo-tula.ru/vytyazhki-dlya-kuhni/'},
  { title: 'Газовые  и электрические плиты', image: five, link: 'http://magazin.teplo-tula.ru/gazovye-i-elektricheskie-plity/'},
  { title: 'Газовые колонки', image: six, link: 'http://magazin.teplo-tula.ru/gazovye-kolonki/'},
  { title: 'Гибкие воздуховоды', image: seven, link: 'http://magazin.teplo-tula.ru/gibkie-vozduhovody-iz-nerzhaveyushchey-stali/'},
  { title: 'Насосы', image: eight, link: 'http://magazin.teplo-tula.ru/nasosy/'},
  { title: 'Отопительные котлы', image: nine, link: 'http://magazin.teplo-tula.ru/otopitelnye-kotly/'},
  { title: 'Радиаторы ', image: ten, link: 'http://magazin.teplo-tula.ru/radiatory/'},
  { title: 'Стабилизаторы напряжения', image: eleven, link: 'http://magazin.teplo-tula.ru/stabilizatory-napryazheniya/'},
  { title: 'Теплообменники (ЗИП)', image: twelve, link: 'http://magazin.teplo-tula.ru/teploobmenniki-zip/'},
  { title: 'Электрические водонагреватели', image: thirteen, link: 'http://magazin.teplo-tula.ru/elektricheskie-vodonagrevateli/'}
];