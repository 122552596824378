import styles from './Advantages.module.scss';

export const Advantages = () => {
  return (
    <div id='advantages' className={styles.advantages}>
      <h2 className={styles.title}>Преимущества</h2>

      <div className={styles.list}>

        <div className={styles.list__item}>
          <p className={styles.list__title}>22</p>
          <p className={styles.list__subtitle}>
            года работы с газовым оборудованием
          </p>
        </div>

        <div className={styles.list__item}>
          <p className={styles.list__title}>{`>20`}</p>
          <p className={styles.list__subtitle}>
            магазинов в Тульской, Московской, Смоленской и др. областях
          </p>
        </div>

        <div className={styles.list__item}>
          <p className={styles.list__title}>{`>25`}</p>
          <p className={`${styles.list__subtitle} ${styles.list__subtitle_dark}`}>
            партнеров со всего мира
          </p>
        </div>

      </div>
    </div>
  );
};
