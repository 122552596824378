import { imagesA, imagesB, imagesC } from './images';
import styles from './Partners.module.scss';


export const Partners = () => {
  return (
    <div id='partners' className={styles.container}>
      <div className={styles.separator}></div>
      <div className={styles.item}></div>
      <div className={styles.partnert}>
        <div className={styles.titleBox}>
          <h2 className={styles.title}>Мы являемся официальным дилером</h2>
        </div>
        <div className={styles.list}>

          <div className={styles.listA}>
            {imagesA.map((item, index) => {
              return (
                <div className={styles.list__item} key={index}>
                  <a
                    style={{
                      backgroundImage: `url(${item.image})`
                    }}
                    className={styles.list__image}
                    href={item.link}
                    target='_blank'
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                </div>
              )
            })}
          </div>

          <div className={styles.listB}>
            {imagesB.map((item, index) => {
              return (
                <div className={styles.list__item} key={index}>
                  <a
                    style={{
                      backgroundImage: `url(${item.image})`
                    }}
                    className={styles.list__image}
                    href={item.link}
                    target='_blank'
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                </div>
              )
            })}
          </div>

          <div className={styles.listC}>
            {imagesC.map((item, index) => {
              return (
                <div className={styles.list__item} key={index}>
                  <a
                    style={{
                      backgroundImage: `url(${item.image})`
                    }}
                    className={styles.list__image}
                    href={item.link}
                    target='_blank'
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                </div>
              )
            })}
          </div>

        </div>
      </div>
    </div>

  );
};
