import styles from './About.module.scss';

export const About = () => {
  return (
    <div id='about' className={styles.about}>

      <div className={styles.info}>
        <h2 className={styles.info__title}>О нас</h2>
        <p className={styles.info__text}>
          Теплотехника - один из главных поставщиков и официальных дилеров производителей газового оборудования Центрального Федерального округа.<br></br><br></br>
          Оптовая торговля газовым оборудованием осуществляется по Тульской, Московской, Рязанской, Калужской, Липецкой, Орловской, Смоленской, Ивановской и другим областям, постоянно расширяя географию своего присутствия.<br></br><br></br>
          Кроме того, для жителей Тульской, Московской и Смоленской областей работает интернет-магазин с пунктами выдачи заказов.<br></br><br></br>
          Все монтажные работы проводятся высококвалифицированными специалистами, проходящими обучение и аккредитацию у производителей газового и теплового оборудования.<br></br><br></br>
          Мы активно развиваем свою торговую сеть, и готовы строить долгосрочные деловые отношения с заказчиками и партнерами.<br></br><br></br>
        </p>
      </div>

      <div className={styles.profile}>
        <div className={styles.profile__img}></div>
        {/* <div className={styles.profile__info}>
          <p className={styles.profile__name}>
            <span className={styles.profile_bold}>Гончаров Олег Станиславович</span><br></br>
            Основатель и владелец компании
          </p>
        </div> */}
      </div>

    </div>
  );
};
