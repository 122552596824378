import styles from './Header.module.scss';

export const Header = () => {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.top}>
          <div className={styles.logo}></div>
          <nav className={styles.navig}>
            <a href="#about" className={styles.navig__link}>О нас</a>
            <a href="#services" className={styles.navig__link}>Услуги</a>
            <a href="#advantages" className={styles.navig__link}>Преимущества</a>
            <a href="#goods" className={styles.navig__link}>Товары</a>
            <a href="#partners" className={styles.navig__link}>Партнёры</a>
            <a href="#contacts" className={styles.navig__link}>Контакты</a>
            <a href="http://magazin.teplo-tula.ru/all_shop" className={styles.navig__link}>Магазины</a>
          </nav>
        </div>
        <h1 className={styles.title}>Центр газового и электрического отопительного оборудования</h1>
        <h2 className={styles.subTitle}>проектирование, продажа, монтаж, сервис и гарантийное обслуживание</h2>
        <a href='http://magazin.teplo-tula.ru/' className={styles.button}>Купить</a>
      </header>

      <div className={styles.separator}></div>
      <div className={styles.item}></div>


      {/* <div className={styles.separator}></div>
      <div className={styles.about}></div> */}
    </>

  );
};
