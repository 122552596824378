import styles from './Footer.module.scss';

export const Footer = () => {
  return (
    <div id='contacts' className={styles.footer}>
      <div className={styles.line}></div>
      <div className={styles.lineTwo}></div>

      <div className={styles.box}>
        <div className={styles.image}></div>
        <div className={styles.info}>
          <p className={styles.info__title}>
            Готовы перейти в интернет-магазин?
          </p>
          <a href='http://magazin.teplo-tula.ru/' className={styles.info__button}>Купить</a>
        </div>
      </div>

      <div className={styles.container}>

        <nav className={styles.navig}>
          <a href="#about" className={styles.navig__link}>О нас</a>
          <a href="#services" className={styles.navig__link}>Услуги</a>
          <a href="#advantages" className={styles.navig__link}>Преимущества</a>
          <a href="#goods" className={styles.navig__link}>Товары</a>
          <a href="#partners" className={styles.navig__link}>Партнёры</a>
          <a href="http://magazin.teplo-tula.ru/all_shop" className={styles.navig__link}>Магазины</a>
        </nav>

        <div className={styles.contacts}>

          <div className={styles.contacts__item}>
            <div className={`${styles.contacts__icon} ${styles.contacts__icon_phone}`}></div>
              <a href="tel: +74872700023"className={styles.contacts__text}>
                8 (4872) 70-00-23
              </a>
              <a href="tel: +74872700025"className={styles.contacts__text}>
                8 (4872) 70-00-25
              </a>
          </div>

          <div className={styles.contacts__item}>
            <div className={`${styles.contacts__icon} ${styles.contacts__icon_map}`}></div>
            <p className={styles.contacts__address}>
              г. Тула, ул. Оборонная, 37
            </p>
          </div>

          <div className={styles.contacts__item}>
            <div className={`${styles.contacts__icon} ${styles.contacts__icon_email}`}></div>
            <p className={styles.contacts__text}>
              <a href="mailto:tarasov@teplo-tula.ru" className={styles.contacts__link}>
                tarasov@teplo-tula.ru
              </a>
            </p>
          </div>

        </div>

        <div className={styles.social}>
          <a
            href="https://vk.com/teplotechnikaservice"
            className={`${styles.social__icon} ${styles.social__icon_vk}`}
          >VK</a>
          <a
            href="https://ok.ru/group/57926629064791"
            className={`${styles.social__icon} ${styles.social__icon_ok}`}
          >OK</a>
        </div>

      </div>
    </div>
  );
};
