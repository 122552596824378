import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import './styles/index.scss';
import { Header } from './components/Header/Header';
import { About } from './components/About/About';
import { Services } from './components/Services/Services';
import { Advantages } from './components/Advantages/Advantages';
import { Goods } from './components/Goods/Goods';
import { Partners } from './components/Partners/Partners';
import { Footer } from './components/Footer/Footer';

function App() {
  return (
    <HttpsRedirect>
      <div className="app">
        <Header />
        <About />
        <Services />
        <Advantages />
        <div className='box'>
          <div className='line'></div>
          {/* <div className='lineTwo'></div> */}
          <Partners />
          <Goods />
        </div>
        <Footer />
      </div>
    </HttpsRedirect>
  );
}

export default App;
