import React from 'react';
import styles from './Services.module.scss';

export const Services = () => {
  return (
    <div id='services' className={styles.services}>

      <div className={styles.container}>
        <div className={styles.info}>
          <h2 className={styles.info__title}>Услуги</h2>
          <div className={styles.list}>

              <div className={styles.list__item}>
                  <div className={`${styles.list__image} ${styles.list__image_one}`}></div>
                  <p className={styles.list__text}>
                    оптовая и розничная продажа
                  </p>
              </div>

              <div className={styles.list__item}>
              <div className={`${styles.list__image} ${styles.list__image_two}`}></div>
                  <p className={styles.list__text}>
                    проектирование и монтаж газового и теплового оборудования, канализации, систем отопления и водоснабжения
                  </p>
              </div>

              <div className={styles.list__item}>
                <div className={`${styles.list__image} ${styles.list__image_three}`}></div>
                  <p className={styles.list__text}>
                    пусконаладка
                  </p>
              </div>

              <div className={styles.list__item}>
                <div className={`${styles.list__image} ${styles.list__image_four}`}></div>
                  <p className={styles.list__text}>
                    сервисное гарантийное и послегарантийное обслуживание
                  </p>
              </div>

          </div>
        </div>

        <div className={styles.image}></div>

      </div>

    </div>
  );
};
