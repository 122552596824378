import one from '../../assets/images/partners/6.jpg'
import two from '../../assets/images/partners/2.jpg'
import three from '../../assets/images/partners/3.jpg'
import four from '../../assets/images/partners/4.jpg'
import five from '../../assets/images/partners/5.jpg'
import six from '../../assets/images/partners/1.jpg'
import seven from '../../assets/images/partners/7.jpg'
import eight from '../../assets/images/partners/8.jpg'
import nine from '../../assets/images/partners/9.jpg'
import ten from '../../assets/images/partners/10.jpg'
import eleven from '../../assets/images/partners/11.jpg'
import twelve from '../../assets/images/partners/12.jpg'
import thirteen from '../../assets/images/partners/13.jpg'

export const imagesA = [
  { title: '', link: 'https://termobrest.ru/', image: one},
  { title: '', link: 'http://www.borino.ru/', image: two},
  { title: '', link: 'http://www.schetpribor.ru/', image: three},
  { title: '', link: 'http://www.gazdevice.ru/', image: four},
  { title: '', link: 'http://www.flama-group.ru/', image: five},
  { title: '', link: 'https://lemax-kotel.ru/', image: six}
];

export const imagesB = [
  { title: '', link: 'http://tgef.ru/', image: seven},
  { title: '', link: 'http://www.analitpribor-smolensk.ru/', image: eight},
  { title: '', link: 'http://www.bosch.ru/', image: nine},
  { title: '', link: 'http://www.rndgaz.ru/', image: ten}
];

export const imagesC = [
  { title: '', link: 'https://ladogaz.ru/', image: eleven},
  { title: '', link: 'http://gaselectro.ru/', image: twelve},
  { title: '', link: 'http://www.gefest.com/', image: thirteen}
];